<header>
  <nav id="main-nav" class="navbar navbar-expand-lg fixed-top">
    <div class="container">
      <a class="navbar-brand" [routerLink]="['/']">
        <img id="logo-header" class="img img-fluid" src="/assets/logos/logo-web.png" alt="Logo">
      </a>
      
      <button #navbarToggler class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav flex-fill d-flex justify-content-evenly">
          <li class="nav-item" routerLinkActive="active">
            <a (click)="collapseNav()" class="nav-link" [routerLink]="['/empresa']">Empresa</a>
          </li>
          <!-- <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/equipo']">Equipo</a>
          </li> -->

          <!--Servicios-->
          <li class="nav-item" routerLinkActive="active" resize>
            <a (click)="collapseNav()" class="nav-link" [routerLink]="['/servicios']" >
              Servicios
            </a>
            <!-- <a class="nav-link dropdown-toggle" [routerLink]="['/servicios']" id="navbarDropdown" data-bs-toggle="dropdown">
              Servicios
            </a> -->
            <!-- <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul> -->
          </li>
          <!--Servicios-->
          
          <li class="nav-item" routerLinkActive="active">
            <a (click)="collapseNav()" class="nav-link" [routerLink]="['/proyectos']">Proyectos</a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a (click)="collapseNav()" class="nav-link" [routerLink]="['/blog']">Blog</a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a (click)="collapseNav()" class="nav-link" [routerLink]="['/contacto']">Contacto</a>
          </li>        
          <li class="btn btn-primary nav-item" *ngIf="authService.isAuthenticated()">
            <a (click)="logout()"><i class="bi bi-person-circle me-2"></i>Logout</a>
          </li>
        </ul>
    </div>
  </div>
  </nav>
</header>


