import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent }
  from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from '../services/security/authService.service';
import { Router } from '@angular/router';

@Injectable()
export class MyInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router) {}

  intercept(request : HttpRequest<any>, next : HttpHandler) : Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(request).pipe(
      catchError((error) => {
        // Verificar si la respuesta contiene indicio de una excepción relacionada con JWT
        if (error.status === 401 && error.error && error.error.message) {
          const errorMessage = error.error.message;
          if (errorMessage.includes('token mal formado') || errorMessage.includes('token expirado') || errorMessage.includes('token vacío') || errorMessage.includes('fail en la firma')) {
            // Redirigir al usuario al inicio de sesión
            this.router.navigate(['/login']);
            // Puedes mostrar un mensaje de error al usuario si lo deseas
          }
        }
        return throwError(error);
      })
    );
  }
}
