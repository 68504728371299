import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  {
    path: 'login', component: LoginComponent
  },
  {
    path:'',
    loadChildren: () => import('./core/inicio/inicio.module').then(m => m.InicioModule)
  },
  {
    path:'empresa',
    loadChildren: () => import('./core/empresa/empresa.module').then(m => m.EmpresaModule) 
  },
  // {
  //   path:'equipo',
  //   loadChildren: () => import('./core/equipo/equipo.module').then(m => m.EquipoModule) 
  // },
  {
    path:'servicios',
    loadChildren: () => import('./core/servicios/servicios.module').then(m => m.ServiciosModule) 
  },
  {
    path:'proyectos',
    loadChildren: () => import('./core/proyectos/proyectos.module').then(m => m.ProyectosModule) 
  },
  {
    path:'blog',
    loadChildren: () => import('./core/blog/blog.module').then(m => m.BlogModule) 
  },
  {
    path:'contacto',
    loadChildren: () => import('./core/contacto/contacto.module').then(m => m.ContactoModule) 
  },
  {
    path:'**',redirectTo:'',

  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
