import { Component } from '@angular/core';
import { AuthService } from '../services/security/authService.service';
import { NivelAlerta, TiposAlerta } from '../utils/Alertas';
import { AlertaService } from '../shared/alertas/alerta.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent {
  username: string = '';
  password: string = '';

  tipoAlerta = TiposAlerta.HTTP;

  constructor(private authService: AuthService,private alerta:AlertaService,private router: Router) {}

  login(): void {
    // Llamar al servicio de autenticación para iniciar sesión
    this.authService.login({ nombreUsuario: this.username, password: this.password })
      .subscribe(
        (dato) => {
          console.log(dato)
          console.log('Login successful'); // Manejar el éxito del inicio de sesión
          // Redirigir a una página después del inicio de sesión
          this.router.navigate(['/']);

        },
        (error) => {
          console.error('Login failed:', error); // Manejar el error del inicio de sesión
          // Mostrar mensaje de error al usuario
          this.alerta.send(this.tipoAlerta,NivelAlerta.ERROR,null,error.status)
        }
      );
  }
}
