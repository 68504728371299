import { Component } from '@angular/core';
import { ErrorHttpService } from './utils/errorHttpManager/errorHttp.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.sass'
})
export class AppComponent {
  title = 'MarianoWeb';


  constructor(private errorHttp:ErrorHttpService){}
}
