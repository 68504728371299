<div id="mainContent" class="container">
  <div class="row">
    <div class="col-12 col-lg-9 col-xl-6 m-auto">
      <h2>Login</h2>
      <alertaCustom></alertaCustom>
      <form (ngSubmit)="login()">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="username" name="username" placeholder="Usuario" [(ngModel)]="username" required>
          <label for="username">Usuario:</label>
        </div>
        <div class="form-floating mb-3">
          <input type="password" class="form-control" id="password" name="password" placeholder="Contraseña" [(ngModel)]="password" required>
          <label for="password">Contraseña:</label>
        </div>
        <button class="btn btn-primary" type="submit"><i class="bi bi-person-circle me-2"></i> Login</button>
      </form>
    </div>
  </div>
</div>