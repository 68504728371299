import { Directive, Output, EventEmitter, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
    selector: "[resize]"
})
export class Resize {
    private stPoint: number;
    private endPoint: number;
    private root: HTMLElement;
    private width: number;

    @Output() resizer = new EventEmitter();

    constructor(private _el: ElementRef) {
        this.root = this._el.nativeElement;        
    }

    @HostListener('mouseover', ['$event'])
    private _onMouseOver(event:any) {               
        if (event.target.nextSibling && event.x > 780) {        
            event.target.nextSibling.style.display = 'block';
        }              
    }

    @HostListener('mouseout', ['$event'])
    private _onMouseOut(event:any) { 
        if (event.target.nextSibling && event.x > 780) {  
            event.target.nextSibling.style.display = 'none';
        }       
    }

   
}