
						
<footer class="site-footer">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4 mr-auto">
            <!-- <h5 class="footer-heading mb-4">IG Estructuras</h5> -->
            <a [routerLink]="['/']">
              <img id="logo-footer" src="/assets/logos/logo-web.png" alt="Logo">
            </a>
            <p>Somos un equipo formado por ingenieros y arquitectos especializados en proyecto y cálculo estructural.</p>
          </div>
          <div class="col-md-3">   
            <p><i class="color-blue fa-solid fa-location-dot  me-2"></i> C/ Maestro Valls, 11, Camins al Grau, 46022, Valencia</p>
            <p><a href="mailto:info@igestructuras.es"><i class="color-blue fa-solid fa-envelope me-2"></i> info&#64;igestructuras.es</a></p>
            <p><a href="tel:+34604887294"><i class="color-blue fa-solid fa-phone me-2"></i> +34 604 88 72 94</a></p>
          </div>
          <div class="col-md-3">
            <!-- <h5 class="footer-heading mb-4">Links</h5> -->
            <ul class="list-unstyled">
              <li class="mb-2">
                <a [routerLink]="['/empresa']">Empresa</a>
              </li>
              <!-- <li>
                <a [routerLink]="['/equipo']">Equipo</a>
              </li> -->
              <li class="mb-2">
                <a [routerLink]="['/servicios']">Nuestros servicios</a>                
              </li>
              <li class="mb-2">
                <a [routerLink]="['/proyectos']">Proyectos</a>
              </li>
              <li class="mb-2">
                <a [routerLink]="['/blog']">Blog</a>
              </li>
              <li>
                <a [routerLink]="['/contacto']">Contacto</a>
              </li>  
            </ul>
          </div>
          <div class="col-md-2">   
            <h5 class="footer-heading mb-4">Síguenos en redes</h5>                                    
            <a class="rrss">
              <i class="fa-brands fa-square-x-twitter"></i>
            </a>
            <a class="rrss">
              <i class="fa-brands fa-square-facebook"></i>
            </a>
            <a class="rrss">
              <i class="fa-brands fa-linkedin"></i>
            </a>
            
          </div>
        </div>
      </div>
      <div class="col-md-3">       
      </div>
    </div>
    <div class="row mt-5 text-center">
      <div class="col-md-12">
        <div class="border-top pt-3">
          <p>
          <small class="block">© {{getYear()}} IG Estructuras. Todos los derechos reservados. <br> Diseñado &amp; Desarrollado por <a href="https://github.com/alejandroDev94" target="_blank">Avizlop</a></small> 
          </p>
        </div>
      </div>      
    </div>
  </div>   
</footer>