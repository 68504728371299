import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../services/security/authService.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild('navbarToggler') navbarToggler:ElementRef;

  changeText: Boolean;
  isDesktop: Boolean;

  constructor(private router: Router,public authService:AuthService) {
    this.changeText = false;
    this.isDesktop = window.innerWidth > 991;

      router.events.subscribe((ruta) =>{
        window.scrollTo(0, 0);
        if (this.isDesktop){
          const navElement = document.getElementById('main-nav');
          const logoElement = document.getElementById('logo-header');
          if (ruta instanceof NavigationEnd) {
            if ( ruta.url !== '/') {
              document.getElementsByTagName('header')[0].style.height = '63px';
              navElement.style.background = '#fffffff2';
              logoElement.style.filter = 'none';
              this.cambioEstilosNav('#222');
            } else {
              document.getElementsByTagName('header')[0].style.height = '0';
              navElement.style.background = 'none';
              logoElement.style.filter = 'brightness(0) invert(1)';
              this.cambioEstilosNav('#fff');
            }
          }            
        }
      });
    
   }



  ngOnInit() {       
  }

  navBarTogglerIsVisible() {
    return this.navbarToggler.nativeElement.offsetParent !== null;
  }
  
  collapseNav() {
    if (this.navBarTogglerIsVisible()) {
      this.navbarToggler.nativeElement.click();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (!this.isDesktop) return;

    const navElement = document.getElementById('main-nav');
    const logoElement = document.getElementById('logo-header');
    if (this.router.url === '/') { // Verifica si estás en la página de inicio
      if (navElement) {
        if (window.scrollY > 0) {
          navElement.style.background = '#fffffff2';
          logoElement.style.filter = 'none';
          this.cambioEstilosNav('#222');
        } else {
          navElement.style.background = 'none';
          logoElement.style.filter = 'brightness(0) invert(1)';
          this.cambioEstilosNav('#fff');

        }
      }
    }else{
      logoElement.style.filter = 'none';
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.isDesktop = window.innerWidth > 991;
  }

  logout() {
    this.authService.logout();
  }

  cambioEstilosNav(color:string):void {
    const elements = Array.from(document.getElementsByClassName('nav-link')) as HTMLElement[];
  
    elements.forEach((element: HTMLElement) => {
      element.style.color = color;
    });
  }

}
