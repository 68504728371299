import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { Resize } from './shared/directives/resize.directive';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MyInterceptor } from './utils/MyInterceptor.service';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { ErrorHttpService } from './utils/errorHttpManager/errorHttp.service';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,        
    Resize,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    SharedModule,    
    HttpClientModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: MyInterceptor, multi: true }, ErrorHttpService],
  bootstrap: [AppComponent]
})
export class AppModule { }
